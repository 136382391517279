<script setup></script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128.002 81.075"
        aria-hidden="true"
        fill="currentColor"
    >
        <g transform="translate(-16 -104)">
            <path
                d="M138.466,143.112,126.4,138.587l-5.239-11.974-3.909,1.71,4.293,9.81H109.868V116.8h2.339l3.173,7.255,3.909-1.71-5.23-11.956A10.672,10.672,0,0,0,104.287,104H24.533A8.543,8.543,0,0,0,16,112.534V116.8h4.267v-4.267a4.272,4.272,0,0,1,4.267-4.267h79.754a6.4,6.4,0,0,1,5.864,3.832l.19.434H97.068A4.272,4.272,0,0,0,92.8,116.8v21.334a4.272,4.272,0,0,0,4.267,4.267h27.347l12.553,4.707a4.286,4.286,0,0,1,2.768,3.994v1.965h-6.4a4.272,4.272,0,0,0-4.267,4.267V161.6h-4.822a14.913,14.913,0,0,0-24.49,0H55.979a14.913,14.913,0,0,0-24.49,0H28.8v-6.4a2.133,2.133,0,0,0-2.133-2.133h-6.4v-32H16v51.2a4.272,4.272,0,0,0,4.267,4.267h9.977a14.931,14.931,0,0,0,27.8-10.667H97.692a14.85,14.85,0,0,0-.469,6.4H62.934v4.267H98.512a14.931,14.931,0,0,0,27.8-10.667h13.425v6.4H131.2v4.267h8.534A4.272,4.272,0,0,0,144,172.268V151.1a8.572,8.572,0,0,0-5.536-7.99ZM97.068,116.8H105.6v21.334H97.068ZM24.533,157.334V161.6H20.267v-4.267Zm-4.267,14.934v-6.4h9.158a14.851,14.851,0,0,0-.469,6.4ZM43.734,180.8A10.667,10.667,0,1,1,54.4,170.135,10.667,10.667,0,0,1,43.734,180.8Zm68.268,0a10.667,10.667,0,1,1,10.667-10.667A10.667,10.667,0,0,1,112,180.8Zm21.334-19.2v-4.267h6.4V161.6Z"
            />
            <path
                d="M48,136h64v4.267H48Z"
                transform="translate(-23.467 -23.466)"
            />
            <path
                d="M48,232h64v4.267H48Z"
                transform="translate(-23.467 -93.866)"
            />
            <path
                d="M358.4,328a6.4,6.4,0,1,0,6.4,6.4A6.4,6.4,0,0,0,358.4,328Zm0,8.534a2.133,2.133,0,1,1,2.133-2.133A2.133,2.133,0,0,1,358.4,336.534Z"
                transform="translate(-246.398 -164.266)"
            />
            <path
                d="M102.4,328a6.4,6.4,0,1,0,6.4,6.4A6.4,6.4,0,0,0,102.4,328Zm0,8.534a2.133,2.133,0,1,1,2.133-2.133A2.133,2.133,0,0,1,102.4,336.534Z"
                transform="translate(-58.666 -164.266)"
            />
            <path
                d="M312,264h8.534v4.267H312Z"
                transform="translate(-217.065 -117.333)"
            />
            <path
                d="M256,264h8.534v4.267H256Z"
                transform="translate(-175.999 -117.333)"
            />
        </g>
    </svg>
</template>
