<script setup></script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 105.76 105.698"
        aria-hidden="true"
        fill="currentColor"
    >
        <g transform="translate(0.538 0.5)">
            <path
                d="M9.412,24.124A1.636,1.636,0,0,0,7.776,25.76V49.173a1.636,1.636,0,0,0,1.636,1.636H47.564A1.636,1.636,0,0,0,49.2,49.173V25.76a1.636,1.636,0,0,0-1.636-1.636ZM45.928,47.537H11.048V27.4h34.88Z"
                transform="translate(9.185 28.496)"
                stroke="currentColor"
                stroke-width="1"
            />
            <path
                d="M10.453,43.5a11.521,11.521,0,0,0,10.976-5.425,11.761,11.761,0,0,0,20.656.447,12.138,12.138,0,0,0,1.647,2.212,11.868,11.868,0,0,0,17.232,0,12.138,12.138,0,0,0,1.647-2.212,11.779,11.779,0,0,0,20.656-.449,11.587,11.587,0,0,0,21.023-9.161L98.807,8.692V1.636A1.636,1.636,0,0,0,97.171,0H7.517A1.636,1.636,0,0,0,5.887,1.636V8.707L.406,28.919A11.6,11.6,0,0,0,10.453,43.5ZM31.9,41.168a8.537,8.537,0,0,1-8.428-9.909l3.366-20.711H41.659L40.5,31.924h0l-.063,1.158A8.544,8.544,0,0,1,31.9,41.168Zm26.683-2.683a8.6,8.6,0,0,1-14.832-6.378l.068-1.259,1.091-20.3H59.746l1.091,20.3.068,1.259a8.518,8.518,0,0,1-2.316,6.378Zm20.706-.323a8.542,8.542,0,0,1-15.033-5.074L64.2,31.931h0L63.04,10.555H77.852l3.366,20.711a8.524,8.524,0,0,1-1.924,6.906Zm20.654-1.481A8.332,8.332,0,0,1,84.863,33.29l-3.7-22.741H95.924l5.207,19.225a8.317,8.317,0,0,1-1.182,6.908ZM9.159,3.272H95.535v4H9.159Zm-5.595,26.5L8.771,10.548H23.529L21.25,24.569l-1,6.164h0l-.414,2.554A8.332,8.332,0,1,1,3.564,29.774Z"
                transform="translate(0.002)"
                stroke="currentColor"
                stroke-width="1"
            />
            <path
                d="M103.062,69.437H98.809V22.495a1.636,1.636,0,1,0-3.272,0V69.437H90.051V29.617a1.636,1.636,0,0,0-1.636-1.636h-21.2a1.636,1.636,0,0,0-1.636,1.636v39.82H9.161V22.512a1.636,1.636,0,1,0-3.272,0V69.437H1.636A1.636,1.636,0,0,0,0,71.073v7.351a1.636,1.636,0,0,0,1.636,1.636H103.062a1.636,1.636,0,0,0,1.636-1.636V71.073A1.636,1.636,0,0,0,103.062,69.437ZM68.848,31.252H86.78V69.437H68.848Zm32.579,45.535H3.272V72.709h98.155Z"
                transform="translate(0 24.639)"
                stroke="currentColor"
                stroke-width="1"
            />
        </g>
    </svg>
</template>
