<script setup></script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 90.244 89.66"
        aria-hidden="true"
        fill="currentColor"
    >
        <g transform="translate(-0.483 -0.67)">
            <path
                d="M82.835,44.77a2.208,2.208,0,0,0,1.974-2.419A39.562,39.562,0,0,0,50.352,6.975L50.833,5.1a3.649,3.649,0,0,0-.644-3.122A3.329,3.329,0,0,0,47.546.67H43.1A3.332,3.332,0,0,0,40.4,2.042a3.643,3.643,0,0,0-.551,3.21l.515,1.71A39.524,39.524,0,0,0,5.813,42.528,2.212,2.212,0,0,0,7.8,44.941c.072.006.144.008.216.008a2.208,2.208,0,0,0,2.2-2,35.27,35.27,0,0,1,70.2-.16,2.215,2.215,0,0,0,2.421,1.977ZM44.418,5.085h1.857l-.4,1.562c-.187,0-.369-.026-.557-.026-.147,0-.288.018-.434.02Z"
            />
            <path
                d="M88.518,47.33H2.691a2.209,2.209,0,0,0,0,4.418H4.223A8.555,8.555,0,0,0,12.466,58.1h8.413C23.628,61.454,31.811,71.37,31.811,71.37c.056.065.115.129.179.191l12.976,12.38,1.307,1.135L44.5,86.305a2.21,2.21,0,1,0,2.523,3.628L64.092,77.995l6.08,5.71A2.209,2.209,0,1,0,73.2,80.488l-7.584-7.126a2.19,2.19,0,0,0-2.494-.336,5.933,5.933,0,0,1-.078-3.264,11.514,11.514,0,0,1,2.545-4.726l5.043-5.614a2.2,2.2,0,0,0,.556-1.316h6.99a8.552,8.552,0,0,0,8.239-6.357h2.1a2.209,2.209,0,0,0,0-4.419ZM62.3,62.086a15.906,15.906,0,0,0-3.531,6.551,10.34,10.34,0,0,0,.73,7.239l-9.487,6.6-2.073-1.8L35.135,68.455c-.676-.816-5.3-6.417-8.533-10.35h3.3s7.472,6.379,7.528,6.419c2.495,1.76,8.221,3.326,10.98,3.326a14.211,14.211,0,0,0,10.36-4.521c.9-.961,1.722-1.812,2.364-2.467a10.051,10.051,0,0,1,3.525-2.4,5.864,5.864,0,0,1,1.187-.332Zm-6.759-1.771a9.786,9.786,0,0,1-7.133,3.118,21.6,21.6,0,0,1-8.362-2.469L36.7,58.1H57.651c-.606.626-1.318,1.363-2.108,2.21Zm22.634-6.626H12.466a4.151,4.151,0,0,1-3.511-1.941H81.684A4.144,4.144,0,0,1,78.177,53.689Z"
            />
            <path
                d="M71.523,37.047a2.206,2.206,0,0,0,1.452-2.764A26.759,26.759,0,0,0,56.693,17.089,2.209,2.209,0,0,0,55.358,21.3a22.285,22.285,0,0,1,13.4,14.293,2.211,2.211,0,0,0,2.109,1.554,2.169,2.169,0,0,0,.658-.1Z"
            />
        </g>
    </svg>
</template>
